<template>
  <div class="container">
    <div class="auth_login_form">
      <div class="va-card mb-4">
        <div class="dash_card_body ">
          <form @submit.prevent="login">
            <div v-if="regError" >
              <div class="alert alert-danger">
                <ul>
                  <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>Name:</label>
              <div>
                <input type="text" v-model="name" @blur="v$.name.$touch" class="form-control" disabled />
              </div>
              <div class="input_error" v-if="v$.name.$error">
                <span v-if="v$.name.required.$invalid">Name is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>Email:</label>
              <div>
                <input type="email" v-model="email" @blur="v$.email.$touch" class="form-control" />
              </div>
              <div class="input_error" v-if="v$.email.$error">
                <span v-if="v$.email.required.$invalid">Email is required</span>
                <span v-if="v$.email.email.$invalid">Email is invalid</span>
              </div>
            </div>
            <div class="form-group">
              <label>Main Issues:</label>
              <div>
                <select class="form-control" v-model="mainIssue">
                  <option value="">-None-</option>
                  <option v-for="issue in issues" :key="issue" :value="issue">{{ issue }}</option>
                </select>
              </div>
              <div class="input_error" v-if="v$.mainIssue.$error">
                <span v-if="v$.mainIssue.required.$invalid">main issue is required</span>
              </div>
  
            </div>
  
            <div class="form-group">
              <label>Description:</label>
              <div>
                <textarea class="form-control" v-model="description" name="" id="" cols="50" rows="10"></textarea>
              </div>
        
  
            </div>
  
  
            <div class="d-flex justify--center mt-3">
              <va-button @click="raiseTicket()" class="my-0"><span v-if="loading" v-html="spinner"></span> <span v-else> Submit</span></va-button>
            </div>
          </form>
  
        </div>
      </div>
    </div>
  
  </div>
</template>
  
  <script>
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import useVuelidate from '@vuelidate/core';
  import { required,email} from '@vuelidate/validators';
  import { useToast } from "vue-toastification";
  const toast = useToast();
  export default {
      setup() {
          return { v$: useVuelidate() }
      },
      data() {
          return {
              name: '',
              mainIssue:'',
              email:'',
              description:'',
              loading:false,
              merchant:[],
              issues:[
                'Failed transaction',
                'Refund',
                'Delivery partner intergration',
                'Close Branch',
                'Orders not posting - Missing orders',
                'third-party integration'
              ],
              spinner: "<i class='fa fa-spinner fa-spin '></i> sending...",
          }
      },
      computed: {
  
      },
      mounted(){
        this.getMerchant();
      },
      validations() {
          return {
              name: { required },
              mainIssue: { required },
              email:{required,email}
          }
      },
      methods: {
        async getMerchant(){
            try{
                const url = `${this.dashBaseUrl}/merchant/${this.merchantID}`;
                const response = await this.axios.get(url, this.apiRequestConfig())
                if (response.status === 200 ) {
                    const merchant = response.data.data;
                    console.log(merchant)
                    this.name = merchant.name
                }
            }catch(err){
                // 
            }
            
        },
          async raiseTicket() {
              this.loading = true;
              const isFormCorrect = await this.v$.$validate()
              if (!isFormCorrect) {
                  this.loading = false;
                  return;
              } else {
                  const payload = {
                      'name': this.name,
                      'email': this.email,
                      'issue': this.mainIssue,
                      'description': this.description,
                  }
                  const url = `${this.dashBaseUrl}/raise-ticket/${this.merchantID}`
                  this.axios.post(url, payload,this.apiRequestConfig() ).then((response) => {
                      if (response.status == 200) {
                        toast.success("ticket raised successfully")
                        this.loading = false;
                      }
                  })
                  .catch((error) => {
                      this.loading = false;
                      if(error.response.status === 400){
                        let errormsgs = '';
                        for (let x = 0; x < error.response.data.errors.length; x++) {
                            errormsgs = error.response.data.errors[x] + ','
                        }
                        toast.error(errormsgs)
                      }  
                  });
              }
          }
      },
      mixins: [ReusableDataMixins]
  }
  </script>
  